var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mega-select", {
    attrs: {
      label: _vm.label,
      placeholder: _vm.placeholder,
      options: _vm.groups
    },
    on: {
      select: function($event) {
        return _vm.$emit("select", $event)
      }
    },
    model: {
      value: _vm._value,
      callback: function($$v) {
        _vm._value = $$v
      },
      expression: "_value"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }