<template>
    <mega-card :title="title" :menu="menu">

        <div class="card-media ratio bg-light" :style="{paddingBottom: padding+'%'}">
            <gmap-map class="fill-parent"
                      ref="mapRef"
                      :center="center"
                      :zoom="17"
                      v-if="visible"
                      @load="address"
                      map-type-id="terrain"
                      style="position: absolute;">

                <gmap-marker :position="position"
                             :clickable="true"
                             :draggable="true"
                             @dragend="updatePosition"/>
            </gmap-map>
        </div>

        <div class="card-body">
            <div class="row">
                <div class="md-down:col-12 col">
                    <mega-input label="Lat" v-model="_lat" @keyup="updateCenter"/>
                </div>
                <div class="md-down:col-12 col">
                    <mega-input label="Lng" v-model="_lng" @keyup="updateCenter"/>
                </div>
            </div>

            <mega-input :warning="!place_name" label="Place Name" v-model="place_name"/>
            <mega-input :warning="!place_address" label="Place Address" v-model="place_address"/>

            <slot></slot>
        </div>
    </mega-card>
</template>

<script>
    export default {
        props: {
            lat: {
                type: Number,
                default: 59.95812333259337
            },
            lng: {
                type: Number,
                default: 30.299955456375073
            },
            ratio: String,
            title: String,
            getLocation: Boolean
        },
        data() {
            return {
                center: {
                    lat: this.lat,
                    lng: this.lng
                },
                position: {
                    lat: this.lat,
                    lng: this.lng
                },
                place_name: '',
                place_address: '',
            }
        },
        computed: {
            _lat: {
                get() { return this.position.lat },
                set(value) {
                    this.position.lat = parseFloat(value) || 0;
                    this.$emit('update', this.position)
                }
            },
            _lng: {
                get() { return this.position.lng },
                set(value) {
                    this.position.lng = parseFloat(value) || 0;
                    this.$emit('update', this.position)
                }
            },
            padding() {
                let r = this.ratio ? this.ratio.toString().split('x') : [4, 3];

                return (r[1] / r[0]) * 100;
            },
            visible() {
                return (this.lat !== undefined && this.lng !== undefined);
            },
            menu() {
                let menu = [];

                if(this.getLocation)
                    menu.push({icon: 'i-crosshair', click: this.getPosition})

                return menu;
            }
        },
        mounted() {
            if(this.getLocation) this.getPosition();
        },
        methods: {

            getPosition() {
                let geo_success = (position) => {
                        this.updatePosition({
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                        }).updateCenter();
                    },
                    geo_error = () => {
                        this.updatePosition({lat: 59.95812333259337, lng: 30.299955456375073}).updateCenter();
                        console.log('Geolocation error');
                };

                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(geo_success, geo_error);
                }
                else geo_error();
            },

            updatePosition(location) {

                if(location.latLng && location.latLng){
                    this._lat = location.latLng.lat();
                    this._lng = location.latLng.lng();
                }
                else if(location.lat && location.lng) {
                    this._lat = location.lat;
                    this._lng = location.lng;
                }

                this.address();
                return this;
            },

            updateCenter(location) {

                if(location && location.lat && location.lng) {
                    this.center = location;
                }
                else {
                    this.center = {
                        lat: this._lat,
                        lng: this._lng
                    }
                }

                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.panTo(this.center)
                })

                this.address();
                return this;
            },

            address() {
                if(!window['google']) return;

                let geoCoder = new window['google'].maps['Geocoder']();

                geoCoder['geocode']( { 'location': this.position}, (results, status) => {
                    if (status === 'OK') {

                        let address = results[0]['address_components'],
                            country = '',
                            route = '',
                            locality = '';

                        address.map(item => {
                            if(item.types.includes('country')) country = item['long_name'];
                            if(item.types.includes('route')) route = item['long_name'];
                            if(item.types.includes('locality')) locality = item['long_name'];
                        })

                        this.place_name = country+', '+locality;
                        this.place_address = route;

                        this.$emit('update', {...this.position, name: this.place_name, address: this.place_address})
                    }
                    else
                        console.log('Geocode was not successful for the following reason: ' + status);
                });
            }
        }
    }
</script>
