var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mega-card", { attrs: { title: _vm.title, menu: _vm.menu } }, [
    _c(
      "div",
      {
        staticClass: "card-media ratio bg-light",
        style: { paddingBottom: _vm.padding + "%" }
      },
      [
        _vm.visible
          ? _c(
              "gmap-map",
              {
                ref: "mapRef",
                staticClass: "fill-parent",
                staticStyle: { position: "absolute" },
                attrs: {
                  center: _vm.center,
                  zoom: 17,
                  "map-type-id": "terrain"
                },
                on: { load: _vm.address }
              },
              [
                _c("gmap-marker", {
                  attrs: {
                    position: _vm.position,
                    clickable: true,
                    draggable: true
                  },
                  on: { dragend: _vm.updatePosition }
                })
              ],
              1
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "card-body" },
      [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "md-down:col-12 col" },
            [
              _c("mega-input", {
                attrs: { label: "Lat" },
                on: { keyup: _vm.updateCenter },
                model: {
                  value: _vm._lat,
                  callback: function($$v) {
                    _vm._lat = $$v
                  },
                  expression: "_lat"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md-down:col-12 col" },
            [
              _c("mega-input", {
                attrs: { label: "Lng" },
                on: { keyup: _vm.updateCenter },
                model: {
                  value: _vm._lng,
                  callback: function($$v) {
                    _vm._lng = $$v
                  },
                  expression: "_lng"
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("mega-input", {
          attrs: { warning: !_vm.place_name, label: "Place Name" },
          model: {
            value: _vm.place_name,
            callback: function($$v) {
              _vm.place_name = $$v
            },
            expression: "place_name"
          }
        }),
        _vm._v(" "),
        _c("mega-input", {
          attrs: { warning: !_vm.place_address, label: "Place Address" },
          model: {
            value: _vm.place_address,
            callback: function($$v) {
              _vm.place_address = $$v
            },
            expression: "place_address"
          }
        }),
        _vm._v(" "),
        _vm._t("default")
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }