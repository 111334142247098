var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "round-post-page" }, [
    _c("section", [
      _c("div", { staticClass: "container py-5" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "md-down:col-12 lg:col-5 xl:col-3" }, [
            _c(
              "div",
              { staticClass: "sticky-top", staticStyle: { top: "85px" } },
              [
                _c(
                  "mega-card",
                  { staticClass: "card-round" },
                  [
                    _c(
                      "mega-drop-zone",
                      {
                        staticClass: "rounded-0",
                        attrs: { type: ["image/jpeg", "image/png"] },
                        on: { change: _vm.renderFile }
                      },
                      [
                        !_vm.preview
                          ? _c("mega-image", {
                              attrs: { ratio: "9x16", src: _vm.preview }
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.preview
                          ? _c("img", {
                              staticClass: "w-100",
                              class: { "opacity-0": _vm.rendering },
                              staticStyle: { transition: ".1s" },
                              attrs: { alt: "", src: _vm.preview }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "md-down:col-12 lg:col-7 xl:col-9" },
            [
              _c("mega-card", { attrs: { title: "Round Options" } }, [
                _c(
                  "div",
                  { staticClass: "card-body" },
                  [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "sm-down:col-12 md-up:col-6" },
                        [
                          _c("mega-select", {
                            attrs: {
                              label: "Select Type",
                              options: _vm.roundTypes
                            },
                            on: { select: _vm.loadModels },
                            model: {
                              value: _vm.round.idc_round_type,
                              callback: function($$v) {
                                _vm.$set(_vm.round, "idc_round_type", $$v)
                              },
                              expression: "round.idc_round_type"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "sm-down:col-12 md-up:col-6" },
                        [
                          _c("mega-select", {
                            attrs: {
                              placeholder: "Select User",
                              label:
                                "Select User" +
                                (_vm.round.idt_user
                                  ? " - " + _vm.round.idt_user
                                  : ""),
                              filter: "",
                              options: _vm.users
                            },
                            on: { search: _vm.search },
                            model: {
                              value: _vm.round.idt_user,
                              callback: function($$v) {
                                _vm.$set(_vm.round, "idt_user", $$v)
                              },
                              expression: "round.idt_user"
                            }
                          })
                        ],
                        1
                      )
                    ]),
                    _vm._v(" "),
                    _vm.round.idc_round_type === 4 ||
                    _vm.round.idc_round_type === 5
                      ? _c(
                          "div",
                          {},
                          [
                            _c("group-select", {
                              attrs: {
                                label: "Model Group",
                                placeholder: "Select group",
                                options: [
                                  { value: undefined, name: "All Items" }
                                ]
                              },
                              on: { select: _vm.loadModels },
                              model: {
                                value: _vm.filter.group,
                                callback: function($$v) {
                                  _vm.$set(_vm.filter, "group", $$v)
                                },
                                expression: "filter.group"
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "mega-slider",
                              {
                                staticClass: "slider mb-3",
                                attrs: { options: _vm.sliderOption }
                              },
                              _vm._l(_vm.models, function(model, i) {
                                return _c(
                                  "mega-slide",
                                  { key: i, staticStyle: { width: "100px" } },
                                  [
                                    _c("mega-image", {
                                      staticClass:
                                        "rounded cursor-pointer image",
                                      class: {
                                        active:
                                          _vm.round.idt_model_modif ===
                                          model.modifications[0][
                                            "idt_model_modif"
                                          ]
                                      },
                                      attrs: {
                                        ratio: "1x1",
                                        src: model.modifications[0]["url_icon"]
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.round.idt_model_modif =
                                            model.modifications[0][
                                              "idt_model_modif"
                                            ]
                                        }
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "mega-button",
                                      {
                                        staticClass: "btn-xs w-100",
                                        on: {
                                          click: function($event) {
                                            _vm.round.idt_model_modif =
                                              model.modifications[0][
                                                "idt_model_modif"
                                              ]
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                                            " +
                                            _vm._s(
                                              model.modifications[0]["name"]
                                            ) +
                                            "\n                                        "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              }),
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("mega-range", {
                      staticClass: "bg-primary mb-0",
                      attrs: {
                        label: "Height (cm)",
                        min: 10,
                        max: 3000,
                        step: 10
                      },
                      model: {
                        value: _vm.round.height,
                        callback: function($$v) {
                          _vm.$set(_vm.round, "height", $$v)
                        },
                        expression: "round.height"
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-between text-muted",
                        staticStyle: { position: "relative", top: "-9px" }
                      },
                      [
                        _c("small", [_vm._v("10")]),
                        _vm._v(" "),
                        _c("small", [_vm._v("3000")])
                      ]
                    ),
                    _vm._v(" "),
                    _c("mega-number", {
                      attrs: { min: 10, max: 3000, step: 10 },
                      model: {
                        value: _vm.round.height,
                        callback: function($$v) {
                          _vm.$set(_vm.round, "height", $$v)
                        },
                        expression: "round.height"
                      }
                    }),
                    _vm._v(" "),
                    _c("mega-textarea", {
                      attrs: { label: "Description", rows: "3" },
                      model: {
                        value: _vm.round.message,
                        callback: function($$v) {
                          _vm.$set(_vm.round, "message", $$v)
                        },
                        expression: "round.message"
                      }
                    })
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c(
                "map-card",
                {
                  attrs: {
                    title: "Round Position",
                    ratio: "16x9",
                    lat: _vm.round.lat,
                    lng: _vm.round.lng
                  },
                  on: { update: _vm.updatePosition }
                },
                [
                  _c("mega-switch", {
                    staticClass: "mb-0 w-100",
                    attrs: { label: "Hide location" },
                    model: {
                      value: _vm.round.is_location_hidden,
                      callback: function($$v) {
                        _vm.$set(_vm.round, "is_location_hidden", $$v)
                      },
                      expression: "round.is_location_hidden"
                    }
                  }),
                  _vm._v(" "),
                  _c("small", { staticClass: "text-muted" }, [
                    _vm._v(
                      "You can hide location from Public, but is still be in Round details"
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "navbar" }, [
          _c(
            "div",
            { staticClass: "navbar-group ml-auto" },
            [
              _c(
                "mega-button",
                {
                  staticClass: "btn-success",
                  attrs: { loading: _vm.loading, disabled: !_vm.valid },
                  on: { click: _vm.submit }
                },
                [_vm._v("Post Round")]
              )
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }