<template>
    <main class="round-post-page">

        <section>
            <div class="container py-5">
                <div class="row">

                    <div class="md-down:col-12 lg:col-5 xl:col-3">
                        <div class="sticky-top" style="top: 85px;">
                            <mega-card class="card-round">
                                <mega-drop-zone class="rounded-0" @change="renderFile" :type="['image/jpeg', 'image/png']">
                                    <mega-image ratio="9x16" :src="preview" v-if="!preview"/>
                                    <img class="w-100"  alt="" style="transition: .1s"
                                         :src="preview"
                                         v-if="preview"
                                         :class="{'opacity-0': rendering}">
                                </mega-drop-zone>
                            </mega-card>
                        </div>
                    </div>

                    <div class="md-down:col-12 lg:col-7 xl:col-9">

                        <mega-card title="Round Options">
                            <div class="card-body">

                                <div class="row">

                                    <div class="sm-down:col-12 md-up:col-6">
                                        <mega-select label="Select Type"
                                                     @select="loadModels"
                                                     v-model="round.idc_round_type"
                                                     :options="roundTypes"/>
                                    </div>

                                    <div class="sm-down:col-12 md-up:col-6">
                                        <mega-select placeholder="Select User"
                                                     :label="'Select User'+(round.idt_user ? ' - '+round.idt_user : '')"
                                                     filter @search="search"
                                                     :options="users"
                                                     v-model="round.idt_user"/>
                                    </div>
                                </div>

                                <div class="" v-if="round.idc_round_type === 4 || round.idc_round_type === 5">

                                    <group-select label="Model Group" placeholder="Select group"
                                                  v-model="filter.group"
                                                  :options="[{value: undefined, name: 'All Items'}]"
                                                  @select="loadModels"/>

                                    <mega-slider class="slider mb-3" :options="sliderOption">

                                        <mega-slide style="width: 100px;"
                                                    v-for="(model, i) in models"
                                                    :key="i">
                                            <mega-image class="rounded cursor-pointer image" ratio="1x1"
                                                        :class="{active: round.idt_model_modif === model.modifications[0]['idt_model_modif']}"
                                                        @click="round.idt_model_modif = model.modifications[0]['idt_model_modif']"
                                                        :src="model.modifications[0]['url_icon']"/>

                                            <mega-button class="btn-xs w-100"
                                                         @click="round.idt_model_modif = model.modifications[0]['idt_model_modif']">
                                                {{model.modifications[0]['name']}}
                                            </mega-button>
                                        </mega-slide>
                                    </mega-slider>
                                </div>

                                <mega-range class="bg-primary mb-0" label="Height (cm)" v-model="round.height" :min="10" :max="3000" :step="10"/>
                                <div class="d-flex justify-content-between text-muted" style="position: relative; top: -9px;">
                                    <small>10</small>
                                    <small>3000</small>
                                </div>
                                <mega-number :min="10" :max="3000" :step="10" v-model="round.height"/>

                                <mega-textarea label="Description" v-model="round.message" rows="3"/>
                            </div>
                        </mega-card>

                        <map-card title="Round Position"
                                  ratio="16x9"
                                  @update="updatePosition"
                                  :lat="round.lat"
                                  :lng="round.lng">

                            <mega-switch class="mb-0 w-100" label="Hide location" v-model="round.is_location_hidden"/>
                            <small class="text-muted">You can hide location from Public, but is still be in Round details</small>
                        </map-card>
                    </div>
                </div>

                <div class="navbar">
                    <div class="navbar-group ml-auto">
                        <mega-button class="btn-success" :loading="loading" :disabled="!valid" @click="submit">Post Round</mega-button>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
    import MapCard from '../Round_Post/components/map'
  import GroupSelect from '../../components/group-select'
  import RoundTypes from '../../../data/round_types.json'

  export default {
    name: 'RoundPostPage',
    props: ['roundId'],
    data() {
      return {
        users: [],
        models: [],
        loading: false,
        rendering: false,
        playVideo: false,
        preview: undefined,
        filter: {
          type: undefined,
          group: undefined,
        },
        sliderOption: {
          spaceBetween: 30,
          slidesPerView: 'auto'
        },
        roundTypes: [
          {
            value: 2,
            name: 'photo'
          },
          ...RoundTypes
        ],
        round: {
          lat: 59.9598743,
          lng: 30.2965115,
          height: 100,
          message: '',
          idt_user: undefined,
          idc_round_type: 2,
          idt_model_modif: undefined,
          preview: undefined,
          image: undefined,
          is_location_hidden: false,
          place_name: '',
          place_address: '',
          profile: {}
        },
      }
    },
    computed: {
      video_link() {
        return this.round.media ? this.round.media['url'] : (this.round.videos ? this.round.videos[0]['url'] : null);
      },
      valid() {
        let required = [
          'idc_round_type',
          'idt_user',
          'preview',
          'lat',
          'lng',
          'height'
        ];
        let valid = true;

        Object.keys(this.round).map(prop =>
        {
          if(required.includes(prop) && this.round[prop] === undefined)
          {
            valid = false;
          }
        });

        return valid;
      }
    },
    mounted() {
      this.$navbar.name = 'Round Post'

      this.loadModels();

      this.$api.v1.get('/userList')
        .then((response) => {
          response.data.users.map(user => {
            this.users.push({
              value: user.idt_user,
              name: user.name+(user.username ? ' ('+user.username+')' : '')
            })
          })
        })
    },
    methods: {

      search(search) {

        if(!search) {
          this.$api.v1.get('/userList')
            .then((response) => {

              this.users = [];

              response.data.users.map(user => {
                this.users.push({
                  value: user.idt_user,
                  name: user.name
                })
              })
            })
        }
        else {
          this.$api.v1.get('/userList', {params: {search}})
            .then((response) => {

              this.users = [];

              response.data.users.map(user => {
                this.users.push({
                  value: user.idt_user,
                  name: user.name+(user.username ? ' ('+user.username+')' : '')
                })
              })
            })
        }
      },

      submit() {
        this.loading = true;

        let data = this.round;
        let formData = new FormData();

        delete data.profile;
        data['height'] = data['height'] * 0.01;

        Object.keys(data).map(prop => {
            if(this.round[prop] !== undefined)
              formData.append(prop, this.round[prop]);
        });

        this.$api.v2.post('/round', formData, {headers: {'Content-Type': 'multipart/form-data'}})
          .then((response) => {
            this.loading = false;
            this.$router.push('/round/'+response.data['round'].idt_round)
          })
      },

      loadModels() {
        let params = {};

        if(this.filter.group !== undefined) params.idt_group = this.filter.group;
        // if(this.round.idc_round_type) params.idc_round_type = this.round.idc_round_type;

        this.$api.v2.get('/models', {params})
          .then(response => {

            this.models = [];

            if(response.data.models)
              response.data.models.map(item => {
                if(item.modifications)
                  this.models.push(item)
              });
          })
      },

      video_toggle() {
        if(this.playVideo)
          this.$refs['video'].pause();
        else
          this.$refs['video'].play();

        this.playVideo = !this.playVideo;
      },

      updatePosition(location) {
        this.round.place_address = location.address;
        this.round.place_name = location.name;
        this.round.lat = location.lat;
        this.round.lng = location.lng;
      },

      renderFile (file) {
        this.round.image = file;
        this.round.preview = file;
        this.rendering = true;

        let reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onloadend = () => {
          setTimeout(() => { this.preview = reader.result }, 100)
          setTimeout(() => { this.rendering = false }, 200)
        };
      }
    },
    components: {
      MapCard,
      GroupSelect
    }
  }
</script>
